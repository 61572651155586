.responsive-logo {
    /* Default styles for larger screens */
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 190px;
  }
  
  @media only screen and (max-width: 768px) {
    /* Styles for screens smaller than 768px (typically mobile devices) */
    .responsive-logo {
      width: 212px;
      height: auto;
      max-width: 100%;
      max-height: 177px;
    }
  }