@import url('https://fonts.googleapis.com/css2?family=The+Nautigal:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');
@import "react-responsive-carousel/lib/styles/carousel.min.css";
/* @import "~react-image-gallery/styles/scss/image-gallery.scss"; */
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


