.offerPageDesign {
  .MuiButtonBase-root.MuiTab-root {
    text-transform: capitalize;
  }
}
.verticalTabsDesign {
  .Mui-selected {
    background-color: #86ac8d;
    border: none !important;
    color: #fff !important;
  }
  .MuiTabs-indicator {
    display: none;
  }
}
.offerListingTabsLayout {
  display: flex;
  width: 100%;
  .offerListingLeftIsde {
    width: 80%;
    border-right: 1px solid #eee;
    height: 500px;
    overflow: auto;
  }
  .offerListingRightSide {
    width: 20%;
    border-right: 1px solid #eee;
    height: 500px;
    overflow: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .offerListingLeftIsde {
    width: 100% !important;
    border-right: none !important;
    height: 500px !important;
    overflow: auto !important;
  }
  .offerListingRightSideMobile {
    height: 300px;
    border-bottom: 1px solid #eee;
    overflow: auto;
  }
  .offerListingTabsLayout {
    display: block;
    width: 100%;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .offerListingLeftIsde {
    width: 100% !important;
    border-right: none !important;
    height: 500px !important;
    overflow: auto !important;
  }
  .offerListingRightSideMobile {
    height: 300px;
    border-bottom: 1px solid #eee;
    overflow: auto;
  }
  .offerListingTabsLayout {
    display: block;
    width: 100%;
  }
}
