.facebook_login_button {
  background-color: white;
  color: black;
  padding: 10px 10px 10px 10px;
  font-weight: 700;
  width: 200px;
}
.google_login_button {
  background-color: white;
  color: black;
  padding: 10px 10px 10px 10px;
  font-weight: 700;
  width: 200px;
}
.apple_login_button {
  background-color: white;
  color: black;
  padding: 10px 10px 10px 10px;
  font-weight: 700;
  width: 200px;
}
.facebookIcon {
  background: url("../../assets/images/facebook-f-brands.svg") transparent 1px
    50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: white;
}
.googleIcon {
  background: url("../../assets/images/1534129544.svg") transparent 1px 50%
    no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: white;
}
.appleIcon {
  background: url("../../assets/images/apple.svg") transparent 1px 50% no-repeat;
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  color: white;
}
.main {
  padding: 40px;
  margin-top: 10%;
  text-align: center;
  border: 3px;

  /* border: 3px solid rgba(255, 255, 255, 0.2);
        border-radius: 50px;
        */
}
.titleBar {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: #42B94C;
}


.responsive-logo {
  /* Default styles for larger screens */
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 190px;
}

@media only screen and (max-width: 768px) {
  /* Styles for screens smaller than 768px (typically mobile devices) */
  .responsive-logo {
    width: 212px;
    height: auto;
    max-width: 100%;
    max-height: 177px;
  }
}