.chatSection {
  width: 100%;
  height: 80vh;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  .conversationsSection {
    height: 710px;
    overflow: auto;
  }
  .messageArea {
    height: 760px;
    overflow: auto;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .chatSection {
    width: 100%;
    height: 1020px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    .conversationsSection {
      height: 300px;
      overflow: auto;
    }
    .messageArea {
      height: 470px;
      overflow: auto;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .chatSection {
    width: 100%;
    height: 1000px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    .conversationsSection {
      height: 300px;
      overflow: auto;
    }
    .messageArea {
      height: 450px;
      overflow: auto;
    }
  }
}
